<template>
	<b-row class="row">
		<b-col v-if="showCountry">
			<b-form-group id="fieldset-1" description="">
				<label for=""><b>All Countries</b></label>
				<v-select 
					v-model="country"
					:options="arrCountries"
					:loading="loading" 
					laceholder=""
					name="country"
				/>
			</b-form-group>
		</b-col>

		<b-col v-if="showProvince">
			<b-form-group id="fieldset-1" description="">
				<label for=""><b>All Provinces</b></label>
				<v-select 
					v-model="province"
					:options="arrProvinces"
					:loading="loading" 
					laceholder=""
					name="country"
				/>
			</b-form-group>
		</b-col>

		<b-col v-if="showDistrict">
			<b-form-group id="fieldset-1" description="">
				<label for=""><b>All Districts</b></label>
				<v-select 
					v-model="district"
					:options="arrDistricts"
					:loading="loading" 
					laceholder=""
					name="country"
				/>
			</b-form-group>
		</b-col>

		<b-col v-if="showInputType">
			<div class="form-group mt-4">
				<input 
					id="name" 
					v-model="name" 
					type="text" 
					class="form-control" 
					:placeholder="placeholder"
				>
			</div>
		</b-col>

		<b-col>
			<label for=""></label>
			<div class="button">
				<button 
					type="button" 
					class="btn btn-primary" 
					@click="filterData"
				>
					<i class="fa fa-filter" aria-hidden="true"></i> Filter
				</button>
				<button 
					type="button" 
					class="btn btn-outline-danger" 
					:disabled="!canReset"
					style="margin-left:1%"
					@click="onReset"
				>
					Reset
				</button>
			</div>			
		</b-col>
	</b-row> 
</template>

<script>
	export default {
		components: {},
		// eslint-disable-next-line vue/require-prop-types
		props : {
			showCountry: {
				type: Boolean,
				default: false
			},
			showProvince: {
				type: Boolean,
				default: false
			},
			showDistrict: {
				type: Boolean,
				default: false
			},
			showInputType: {
				type: Boolean,
				default: false
			},
			// eslint-disable-next-line vue/require-default-prop
			placeholder: String,

		},
		data() {
			return{
				arrCountries: [],
				country: null,

				arrProvinces: [],
				province: null,

				arrDistricts:[],
				district: null,

				name:null
			}
		},
		computed: {
			canReset() {
				return this.country != null
					|| this.province != null
					|| this.district != null
					|| this.name != null	
			},
		},
		watch:{
			country: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getAllProvinces()
						this.arrProvinces = []
					}
				}
			},
			province: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getAllDsitricts()
						this.arrDistricts = []
					}
				}
			},
		},
		created(){
			this.getAllCountries()
		},
		methods: {
			getAllCountries() {
				this.$http
					.Get({}, this.$urlApi.fieldAgent.getCountries)
					.then((r) => {
						this.arrCountries = [];
						r.results.forEach(async (value, index) => {
							await this.arrCountries.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})
				
			},
			getAllProvinces() {
				let params = {CountryID: this.country.id}
				this.$http
					.Get(params, this.$urlApi.fieldAgent.getProvinces)
					.then((r) => {
						this.arrProvinces = [];
						r.results.forEach(async (value, index) => {
							await this.arrProvinces.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},

			getAllDsitricts() {
				let params = {ProvinceID: this.province.id}
				this.$http
					.Get(params, this.$urlApi.fieldAgent.getDistricts)
					.then((r) => {
						this.arrDistricts = [];
						r.results.forEach(async (value, index) => {
							await this.arrDistricts.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},
			onReset() {
				this.country = null
				this.province = null
				this.district = null
				this.name = null

				this.filterData()
			},
			filterData(){
				// eslint-disable-next-line vue/require-explicit-emits
				this.$emit('change', 
					{	
						Country: this.country || "",
						Province: this.province || "",
						District: this.district || "",
						Name: this.name || "",
							
					})
			},
		}
	}
</script>

<style scoped>

</style>