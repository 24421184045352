<template>
	<div class="main-content">
		<ktv-breadcrumb title="Report Registered" page="Report" folder="Farmer" />	
		<Search
			:show-input-type="true" 
			@change="onSearchFilter"
		/>
		
		<table class="table table-hover ">
			<thead class="thead">
				<tr class="">
					<th>ID Submitted</th>
					<th>Status</th>
					<th>Submitted By</th>
					<th>Submitted Date</th>
					<th>Download Data</th>
				</tr>
			</thead>
			<tbody>
				<!-- <tr v-for="(a, key) in rows" :key="key"> -->
				<tr>
					<td>F11012</td>
					<td>Close</td>
					<td>Budi</td>
					<td>2022-08-09 11:00 PM</td>
					<td>
						<a
							href="https://koltiva-central.s3.ap-southeast-1.amazonaws.com/frontend/bulk-farmer-upload-template/Farmer_Upload_Template.csv"
						>	Registered FIle Name 
						</a>
					</td>
				</tr>
				<tr>
					<td>F113432</td>
					<td>Close</td>
					<td>Jodi</td>
					<td>2022-08-09 11:00 PM</td>
					<td>
						<a
							href="https://koltiva-central.s3.ap-southeast-1.amazonaws.com/frontend/bulk-farmer-upload-template/Farmer_Upload_Template.csv"
						>	Registered FIle Name 
						</a>
					</td>
				</tr>
				<tr>
					<td>K454545</td>
					<td>Close</td>
					<td>Anggun</td>
					<td>2022-08-09 11:00 PM</td>
					<td>
						<a
							href="https://koltiva-central.s3.ap-southeast-1.amazonaws.com/frontend/bulk-farmer-upload-template/Farmer_Upload_Template.csv"
						>	Registered FIle Name 
						</a>
					</td>
				</tr>
			</tbody>
		</table>

		<paginationBarCustom 
			:state="paginationData" 
			@paginate="onPageChange()"
		/>
	</div>
</template>

<script>
	import {
		// KtvButton,
	} from "@/components";
	import paginationBarCustom from "@/components/paginationBarCustom"
	import Search from "@/components/searchCustom"

	export default {
		metaInfo: {
			title: "Farmer",
		},
		components: {
			paginationBarCustom,
			Search,
		},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				
				paginationData: {
					pagination: {
						current_page: 1
					},
				},

			}
		},
		computed: {},
		watch: {

		},
		mounted() {
		},
		methods: {
			
		}
	}
</script>

<style>
.dropdownitem-action:hover a,
.dropdownitem-action:focus a {
	background-color: #CAEAFF !important;
}
</style>