<template>
	<div v-if="state && state.pagination" class="justify-content-center pagination" aria-label="pagination">     
		<li class="page-item" :class="{disabled : state.pagination.current_page < 2}">
			<button class="page-link buttonCustom">
				<span class="" @click="changePage(1)"><i class="fa fa-angle-double-left fa-2xl" aria-hidden="true"></i></span>
			</button>
		</li>   
		<li class="page-item" :class="{disabled : state.pagination.current_page < 2}">
			<button class="page-link buttonCustom">
				<span class="" @click="changePage(state.pagination.current_page-1)"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
			</button>
		</li>
		<li v-for="(page, index) in pages" :key="index" class="page-item"
			:class="isCurrentPage(page) ? 'active' : ''"
		>
			<button class="page-link buttonCustom" @click="changePage(page)">
				<span class="">{{ page }}</span>
			</button>
		</li>
		<li class="page-item" :class="{disabled : state.pagination.current_page >= state.pagination.total_page}">
			<button class="page-link buttonCustom">
				<span class="" @click="changePage(state.pagination.current_page+1)"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
			</button>
		</li>
		<li class="page-item" :class="{disabled : state.pagination.current_page >= state.pagination.total_page}">
			<button class="page-link buttonCustom">
				<span class="" @click="changePage(state.pagination.total_page)"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>
			</button>
		</li>

		<li class="page-item" :class="{disabled : state.pagination.current_page >= state.pagination.total_page}">
			<button class="page-link buttonCustom">
				<span class="">Total: {{ state.pagination.total }}</span>
			</button>
		</li>
	</div>  
</template>

<script>
	export default {
		components: {},
		// eslint-disable-next-line vue/require-prop-types
		props : ['state'],
		data() {
			return{
				offset: 5
			}
		},
		computed: {
			pages() {
				let pages = [];
				let from = this.state.pagination.current_page - Math.floor(this.offset / 2);
				if (from < 1) {
					from = 1;
				}
				let to = from + this.offset - 1;
				if (to > this.state.pagination.total_page) {
					to = this.state.pagination.total_page;
				}
				while (from <= to) {
					pages.push(from);
					from++;
				}
				return pages;
      
			}
		},
		methods: {
			isCurrentPage(page) {
				return this.state.pagination.current_page === page;
			},
			changePage(page) {
				if (page > this.state.pagination.total_page) {
					page = this.state.pagination.total_page;
				}

				// eslint-disable-next-line vue/no-mutating-props
				this.state.pagination.current_page = page;
				// eslint-disable-next-line vue/require-explicit-emits
				this.$emit('paginate', page);
			},
		}
	}
</script>

<style scoped>
  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #727b8478;
    border-color: #727b8478;
    font-size: 16px;
    padding: 3px 15px;
}
.buttonCustom{
    font-size: 16px;
    padding: 3px 15px;
}
.page-link {
    color: #000000;
}
</style>